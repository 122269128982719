<template>
  <div>
    <select class="m-2" style="text-align: right;" v-model="tahun" @change="onChangeTahun($event)">
      <option v-for="(value) in tahuns" :key="value.id_poktan">{{ value.id_poktan }}</option>
    </select>
    <select  class="m-2" style="text-align: right;" v-model="kecamatan_" @change="onChangeKecamatan($event)">
      <option v-for="kecamatan in kecamatans" :value="kecamatan.name" :key="kecamatan.name">{{ kecamatan.name }}</option>
    </select>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="kecamatan"
  />
  </div>
  
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'ChartLuasLahanKecamatan',
  components: { CChartBar },
  data() {
    return {
      kecamatan: [],
      tidak_dipertahankan: [],
      dipertahankan: [],
      cadangan: [],
      irigasi_macro: [],
      total: [],
      colors: [],
      tahuns: [],
      tahun: null,
      kecamatans: [],
      kecamatan_: null,
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getData() {
      this.$store.dispatch("lp2b/totalLuasKelurahan", { tahun: this.tahun, kecamatan: this.kecamatan_ }).then((resp) => {

      resp.cadangan.forEach(element => {
        this.kecamatan.push(element.desa)
        this.cadangan.push(element.total)
      });

      this.irigasi_macro = [
        {
          label: 'Luas Lahan',
          backgroundColor: this.getRandomColor(),
          data: this.cadangan
        },
      ]

      }).catch(e => {
        this.$toast.error(e);
      });
    },
    getKecamatan() {
      this.$store
        .dispatch("lp2b/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data;
          this.kecamatan_ = this.kecamatans[0].name
          this.getData();
        })
        .catch((e) => {
          console.log(e)
          // this.$toast.error("gagal mengambil data  \n", e);
        });
    },
    onChangeTahun() {
      this.getData();
    },
    onChangeKecamatan() {
      this.getData();
    }
  },
  computed: {
    defaultDatasets () {
      return this.irigasi_macro
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    },
    
  },
  mounted() {
    this.$store.dispatch("lp2b/getTahun").then((resp) => { 
      this.tahuns = resp;
      this.tahun = this.tahuns[0].id_poktan;
      this.getKecamatan();
    });
  },
}
</script>
