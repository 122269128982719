<template>
  <div>
    <div class="card shadow rounded-lg p-1">
      <CTabs variant="pills" :active-tab="0">
        <!-- <CTab title="Irigasi Tersier">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Irigasi Tersier Macro berdasarkan Jenis Lahan (%)</b></p>
                  <hr />
                  <CChartDoughnutExample />
                </div>
              </div>
              <div class="col-md-6">
              <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Irigasi Tersier Macro berdasarkan hasil survey (%) </b></p>
                  <hr />
                  <ChartBySurvey />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Jumlah Irigasi Tersier Macro berdasarkan Kecamatan</b></p>
                  <hr />
                  <CChartBarExample />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Jumlah Irigasi Tersier Macro berdasarkan survey per Kecamatan </b></p>
                  <hr />
                  <div>
                    <ChartSurveyByKecamatan />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Total Panjang Jaringan Irigasi Tersier berdasarkan Kecamatan (Ha) </b></p>
                  <hr />
                    <div>
                      <ChartTotalPanjang />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </CTab> -->
        <CTab title="Lahan LP2B">
          <div class="container">
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Lahan LP2B Bersarkan Status Lahan (%)</b></p>
                  <hr />
                  <ChartPersentaseLahanStatus />
                </div>
              </div>
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Total Luas Lahan Berdasakan Status Lahan (Ha)</b></p>
                  <hr />
                  <ChartLuasStatus />
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Total Luas Lahan LP2B berdasarkan Kecamatan (Ha)</b></p>
                  <hr />
                  <div>
                    <ChartLuasLahanKecamatan />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Total Luas Lahan LP2B berdasarkan Kelurahan (Ha)</b></p>
                  <hr />
                  <div>
                    <ChartLuasLahanKelurahan />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Jumlah Kapasitas Produksi Kecamatan</b></p>
                  <hr />
                  <ChartTotalPanjang />
                </div>
              </div>
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Jumlah Bantuan Perjenis Bantuan, Kecamatan </b></p>
                  <hr />
                  <ChartBantuan />
                </div>
              </div>
            </div>
          </div>
        </CTab>
      </CTabs>
    </div>
  </div>
</template>



<script>
import axios from "axios";
import ScheduleTable from "./components/schedule-table.vue";
import CChartDoughnutExample from "../charts/CChartDoughnutExample.vue";
import CChartBarExample from "../charts/CChartBarExample.vue";
import CChartBarSimple from "../charts/CChartBarSimple.vue";
import ChartBySurvey from "../charts/ChartBySurvey.vue";
import ChartSurveyByKecamatan from "../charts/ChartSurveyByKecamatan.vue";
import ChartTotalPanjang from "../charts/ChartTotalPanjang.vue";
import ChartPersentaseLahanStatus from "../charts/ChartPersentaseLahanStatus.vue";
import ChartLuasStatus from "../charts/ChartLuasStatus.vue";
import ChartLuasLahanKecamatan from "../charts/ChartLuasLahanKecamatan.vue";
import ChartLuasLahanKelurahan from "../charts/ChartLuasLahanKelurahan.vue";
import ChartBantuan from "../charts/ChartBantuan.vue";

export default {
  components: {
    ScheduleTable,
    CChartDoughnutExample,
    CChartBarExample,
    CChartBarSimple,
    ChartBySurvey,
    ChartSurveyByKecamatan,
    ChartTotalPanjang,
    ChartPersentaseLahanStatus,
    ChartLuasStatus,
    ChartLuasLahanKecamatan,
    ChartBantuan,
    ChartLuasLahanKelurahan
  },
  data() {
    return {
      user: {},
      updateModal: false,
      form: {},
      agenda: [],
      roles: [],
      rooms: [],
      inbox: [],
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 3,
      },
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      axios
        .get("/agenda", { params: this.params })
        .then((resp) => {
          this.agenda = resp.data.data.data;

          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    editUser() {
      this.form = this.user;
      this.updateModal = true;
    },
    update() {
      var loading = this.$loading.show();
      if (this.form.password == null) {
        this.$store
          .dispatch("user/updateUser", { id: this.form.id, data: this.form })
          .then(() => {
            this.$toast.success("Berhasil merubah data user");
            loading.hide();
            this.updateModal = false;
            this.form = {};
            this.getMe();
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      } else {
        this.$store
          .dispatch("user/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$store
              .dispatch("user/updateUser", {
                id: this.form.id,
                data: this.form,
              })
              .then(() => {
                this.$toast.success("Berhasil merubah data user");
                loading.hide();
                this.updateModal = false;
                this.form = {};
                this.getMe();
              })
              .catch((e) => {
                this.$toast.error(e);
                this.updateModal = false;
                loading.hide();
              });
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      }
    },
    getMe() {
      this.$store
        .dispatch("auth/me")
        .then((resp) => {
          this.user = resp.data;
          this.getInbox();
          this.getRooms();
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRooms() {
      var params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
        role_id: this.user.role.id,
      };
      this.$store
        .dispatch("room/getRoom", params)
        .then((resp) => {

          this.rooms = resp.data;
          console.log("room", this.rooms);
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getInbox() {
      var _params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
      };
      this.$store
        .dispatch("message/getInbox", {
          id: this.user.role.id,
          params: _params,
        })
        .then((resp) => {
          this.inbox = resp.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRole() {
      let _params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
      };
      this.$store
        .dispatch("role/getRole", _params)
        .then((resp) => {
          this.roles = resp.data.data;
          console.log(this.roles);
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data role \n", e);
        });
    },
  },
  mounted() {
    // this.getMe();
    // this.getData();
    // this.getRole();
  },

  computed: {
    computedRole() {
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

.scroll {
  overflow-y: auto;
  height: 150px;
}


</style>

<style>
table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.tab-content {
  margin-top: 20px !important;
}
</style>