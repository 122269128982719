<template>
  <div>
    <select style="text-align: right;" v-model="tahun">
      <option v-for="(value) in tahuns" :key="value.id_poktan">{{ value.id_poktan }}</option>
    </select>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="kecamatan"
  />
  </div>
  
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'ChartLuasLahanKecamatan',
  components: { CChartBar },
  data() {
    return {
      kecamatan: [],
      tidak_dipertahankan: [],
      dipertahankan: [],
      cadangan: [],
      irigasi_macro: [],
      total: [],
      colors: [],
      tahuns: [],
      tahun: null,
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getDate() {
      this.$store.dispatch("lp2b/totalLuasKecamatan", { tahun: this.tahun }).then((resp) => {

      resp.cadangan.forEach(element => {
        this.kecamatan.push(element.kecamatan)
        this.cadangan.push(element.total)
      });

      this.irigasi_macro = [
        {
          label: 'Luas Lahan',
          backgroundColor: this.getRandomColor(),
          data: this.cadangan
        },
      ]

      }).catch(e => {
        this.$toast.error(e);
      });
    }
  },
  computed: {
    defaultDatasets () {
      return this.irigasi_macro
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    },
   
  },
  mounted() {
    this.$store.dispatch("lp2b/getTahun").then((resp) => { 
      console.log(resp)
      this.tahuns = resp;
      this.tahun = this.tahuns[0].id_poktan;
      this.getDate();
    });
  },
}
</script>
